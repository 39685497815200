<template>
  <main>
    <div class="mr-4 md:mr-0 mt-4">
      <h1 class=" text-xl font-semibold">User Guides</h1>
      <div class="mt-5 w-full grid md:flex">
        <div class="w-full max-w-sm">
          <div
            class=" cursor-pointer"
            @click="current = i + 1"
            v-for="(text, i) in texts"
            :key="i"
          >
            <div
              :class="{
                'border-primaryBlue sm text-primaryBlue': current === i + 1,
              }"
              class="border px-3 py-4 text-sm mb-3 rounded-md"
            >
              <div class="flex items-center">
                <div
                  class="w-8 h-8 text-white vvv rounded-full bg-gray-500 cursor-pointer  hover:bg-gray-700 flex justify-center items-center"
                >
                  {{ i + 1 }}
                </div>
                <span class="ml-5 vva text-gray-700">
                  {{ text }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full justify-center items-center cursor-pointer">
          <div
            style="height:32rem"
            class=" w-full shadow-md rounded-lg max-w-2xl mx-auto flex justify-center items-center"
          >
            <video
              autoplay
              class="w-full rounded-lg h-full"
              :src="video"
              alt=""
            ></video>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      texts: [
        "Adding a settlement Account",
        "How to add a product",
        "Selecting Payment Method",
        "Selecting Fixed Account",
      ],
      current: 1,
    };
  },
  computed: {
    video() {
      return require(`../../assets/videos/${this.current}.mp4`);
    },
  },
};
</script>

<style>
.sm .vvv {
  background: #253b95 !important;
}
.sm .vva {
  color: black !important;
}
</style>
